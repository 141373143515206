import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  CircularProgress,
  Card,
  CardContent,
  Tooltip,
  SvgIcon,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CampaignIcon from '@mui/icons-material/Campaign';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import axios from 'axios';

// API URL
const API_URL = 'http://localhost:5001';

interface Campaign {
  campaign_id: string;
  name: string;
  status: string;
  budget: number;
  start_date: string;
  end_date?: string;
}

interface StatCardProps {
  title: string;
  value: string | number;
  icon: typeof SvgIcon;
  color: string;
}

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.background.paper,
}));

const IconWrapper = styled(Box)<{ color: string }>(({ theme, color }) => ({
  backgroundColor: `${color}15`,
  borderRadius: '50%',
  padding: theme.spacing(1),
  marginRight: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StatCard: React.FC<StatCardProps> = ({ title, value, icon: Icon, color }) => (
  <StyledCard>
    <CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconWrapper color={color}>
          <Icon sx={{ color }} />
        </IconWrapper>
        <Typography variant="h6" component="div" sx={{ color: 'text.primary' }}>
          {title}
        </Typography>
      </Box>
      <Typography variant="h4" component="div" sx={{ color }}>
        {value}
      </Typography>
    </CardContent>
  </StyledCard>
);

const CampaignManagement: React.FC = () => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(null);
  const [formData, setFormData] = useState({
    name: '',
    budget: '',
    objective: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const fetchCampaigns = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/v16.0/graphql/ad_groups`, {
        email: localStorage.getItem('userEmail'),
      });

      setCampaigns(response.data.campaigns || []);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePauseCampaign = async (campaignId: string) => {
    try {
      await axios.post(`${API_URL}/v16.0/pause_ad_campaign`, {
        email: localStorage.getItem('userEmail'),
        campaign_id: campaignId,
      });

      fetchCampaigns();
    } catch (error) {
      console.error('Error pausing campaign:', error);
    }
  };

  const handleActivateCampaign = async (campaignId: string) => {
    try {
      await axios.post(`${API_URL}/v16.0/activate_ad_campaign`, {
        email: localStorage.getItem('userEmail'),
        campaign_id: campaignId,
      });

      fetchCampaigns();
    } catch (error) {
      console.error('Error activating campaign:', error);
    }
  };

  const handleOpenDialog = (campaign?: Campaign) => {
    if (campaign) {
      setSelectedCampaign(campaign);
      setFormData({
        name: campaign.name,
        budget: campaign.budget.toString(),
        objective: '',
      });
    } else {
      setSelectedCampaign(null);
      setFormData({
        name: '',
        budget: '',
        objective: '',
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedCampaign(null);
  };

  const handleSubmit = async () => {
    try {
      if (selectedCampaign) {
        // Update existing campaign
        setCampaigns(campaigns.map(campaign =>
          campaign.campaign_id === selectedCampaign.campaign_id
            ? {
                ...campaign,
                name: formData.name,
                budget: parseFloat(formData.budget),
              }
            : campaign
        ));
      } else {
        // Create new campaign
        const newCampaign: Campaign = {
          campaign_id: Date.now().toString(),
          name: formData.name,
          budget: parseFloat(formData.budget),
          status: 'ACTIVE',
          start_date: new Date().toISOString().split('T')[0],
        };
        setCampaigns([...campaigns, newCampaign]);
      }
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving campaign:', error);
    }
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'active':
        return 'success';
      case 'paused':
        return 'warning';
      case 'inactive':
        return 'error';
      default:
        return 'default';
    }
  };

  const stats = {
    totalCampaigns: campaigns.length,
    activeCampaigns: campaigns.filter(c => c.status.toLowerCase() === 'active').length,
    totalBudget: campaigns.reduce((acc, c) => acc + c.budget, 0),
    avgBudget: campaigns.length ? 
      (campaigns.reduce((acc, c) => acc + c.budget, 0) / campaigns.length).toFixed(2) : 
      0,
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h5" sx={{ color: 'text.primary' }}>
          Campaign Management
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
          sx={{ 
            backgroundColor: 'primary.main',
            '&:hover': { backgroundColor: 'primary.dark' }
          }}
        >
          New Campaign
        </Button>
      </Box>

      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Campaigns"
            value={stats.totalCampaigns}
            icon={CampaignIcon}
            color="#2196f3"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Active Campaigns"
            value={stats.activeCampaigns}
            icon={TrendingUpIcon}
            color="#66bb6a"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Budget"
            value={`$${stats.totalBudget}`}
            icon={MonetizationOnIcon}
            color="#f50057"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Average Budget"
            value={`$${stats.avgBudget}`}
            icon={MonetizationOnIcon}
            color="#ffa726"
          />
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ backgroundColor: 'background.paper' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Campaign Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Budget</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campaigns.map((campaign) => (
              <TableRow 
                key={campaign.campaign_id}
                sx={{ '&:hover': { backgroundColor: 'action.hover' } }}
              >
                <TableCell>{campaign.name}</TableCell>
                <TableCell>
                  <Chip
                    label={campaign.status}
                    color={getStatusColor(campaign.status) as any}
                    size="small"
                    sx={{ minWidth: '90px' }}
                  />
                </TableCell>
                <TableCell align="right">${campaign.budget}</TableCell>
                <TableCell>{new Date(campaign.start_date).toLocaleDateString()}</TableCell>
                <TableCell>
                  {campaign.end_date ? new Date(campaign.end_date).toLocaleDateString() : '-'}
                </TableCell>
                <TableCell align="center">
                  <Tooltip title={campaign.status === 'ACTIVE' ? 'Pause Campaign' : 'Activate Campaign'}>
                    <IconButton
                      onClick={() => campaign.status === 'ACTIVE' 
                        ? handlePauseCampaign(campaign.campaign_id)
                        : handleActivateCampaign(campaign.campaign_id)
                      }
                      size="small"
                      sx={{ 
                        color: campaign.status === 'ACTIVE' ? 'warning.main' : 'success.main',
                        '&:hover': { 
                          backgroundColor: 'action.hover',
                        }
                      }}
                    >
                      {campaign.status === 'ACTIVE' ? <PauseIcon /> : <PlayArrowIcon />}
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog 
        open={openDialog} 
        onClose={handleCloseDialog}
        PaperProps={{
          sx: { backgroundColor: 'background.paper' }
        }}
      >
        <DialogTitle sx={{ color: 'text.primary' }}>
          {selectedCampaign ? 'Edit Campaign' : 'New Campaign'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Campaign Name"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'rgba(255, 255, 255, 0.23)',
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgba(255, 255, 255, 0.23)',
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Budget"
                type="number"
                value={formData.budget}
                onChange={(e) => setFormData({ ...formData, budget: e.target.value })}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'rgba(255, 255, 255, 0.23)',
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgba(255, 255, 255, 0.23)',
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} sx={{ color: 'text.secondary' }}>
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained"
            sx={{ 
              backgroundColor: 'primary.main',
              '&:hover': { backgroundColor: 'primary.dark' }
            }}
          >
            {selectedCampaign ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CampaignManagement; 