import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Tooltip,
  CircularProgress,
  Chip,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Stack,
  TableSortLabel,
  Alert,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import LoginIcon from '@mui/icons-material/Login';
import CampaignIcon from '@mui/icons-material/Campaign';

const API_URL = 'http://localhost:5001';

interface User {
  email: string;
  userid: string;
  device_id: string;
  created_at: string;
  last_successful_login: string;
  status: string;
}

interface Account {
  account_id: string;
  name: string;
  active_campaigns: number;
}

interface AccountsResponse {
  accounts: Account[];
  total_accounts: number;
  selected_account: string | null;
  last_updated: string;
  success?: boolean;
  error?: string;
}

type SortField = 'email' | 'created_at' | 'last_successful_login' | 'status';
type SortOrder = 'asc' | 'desc';

const AccountManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalUsers, setTotalUsers] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<string>('all');
  const [sortField, setSortField] = useState<SortField>('email');
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
  const [error, setError] = useState<string | null>(null);
  const [accountsDialogOpen, setAccountsDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [accountsLoading, setAccountsLoading] = useState(false);
  const [accountsError, setAccountsError] = useState<string | null>(null);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await fetch(`${API_URL}/v16.0/get_all_users`);
      const data = await response.json();
      
      if (data.success) {
        setUsers(data.users);
        setFilteredUsers(data.users);
        setTotalUsers(data.total_users);
      } else {
        setError('Failed to fetch users');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Error connecting to server');
    } finally {
      setLoading(false);
    }
  };

  const fetchUserAccounts = async (email: string) => {
    try {
      setAccountsLoading(true);
      setAccountsError(null);
      
      const response = await fetch(`${API_URL}/v16.0/accounts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      
      const data: AccountsResponse = await response.json();
      
      if (response.ok && data.accounts) {
        setAccounts(data.accounts);
      } else {
        setAccountsError(data.error || 'Failed to fetch accounts');
      }
    } catch (error) {
      console.error('Error fetching accounts:', error);
      setAccountsError('Error connecting to server');
    } finally {
      setAccountsLoading(false);
    }
  };

  const handleUserClick = async (user: User) => {
    setSelectedUser(user);
    setAccountsDialogOpen(true);
    await fetchUserAccounts(user.email);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    let result = [...users];

    // Apply status filter
    if (statusFilter !== 'all') {
      result = result.filter(user => user.status === statusFilter);
    }

    // Apply search filter
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      result = result.filter(user =>
        user.email.toLowerCase().includes(searchLower) ||
        user.userid.toLowerCase().includes(searchLower) ||
        user.device_id.toLowerCase().includes(searchLower)
      );
    }

    // Apply sorting
    result.sort((a, b) => {
      let compareResult = 0;
      if (sortField === 'email') {
        compareResult = a.email.localeCompare(b.email);
      } else if (sortField === 'status') {
        compareResult = (a.status || '').localeCompare(b.status || '');
      } else {
        const aDate = new Date(a[sortField] || 0).getTime();
        const bDate = new Date(b[sortField] || 0).getTime();
        compareResult = aDate - bDate;
      }
      return sortOrder === 'asc' ? compareResult : -compareResult;
    });

    setFilteredUsers(result);
  }, [users, searchTerm, statusFilter, sortField, sortOrder]);

  const formatDate = (dateString: string | null) => {
    if (!dateString) return 'Not Available';
    return new Date(dateString).toLocaleString();
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'active':
        return 'success';
      case 'inactive':
        return 'error';
      case 'pending':
        return 'warning';
      default:
        return 'default';
    }
  };

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1" sx={{ color: 'primary.main' }}>
          User Management
          <Typography component="span" variant="h6" sx={{ ml: 2, color: 'text.secondary' }}>
            ({filteredUsers.length} / {totalUsers} Users)
          </Typography>
        </Typography>
        <Tooltip title="Refresh">
          <IconButton onClick={fetchUsers} disabled={loading}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
        <TextField
          placeholder="Search users..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ width: 300 }}
          InputProps={{
            startAdornment: <SearchIcon sx={{ color: 'action.active', mr: 1 }} />,
          }}
        />
        <FormControl sx={{ width: 200 }}>
          <InputLabel>Status Filter</InputLabel>
          <Select
            value={statusFilter}
            label="Status Filter"
            onChange={(e) => setStatusFilter(e.target.value)}
            startAdornment={<FilterListIcon sx={{ color: 'action.active', mr: 1 }} />}
          >
            <MenuItem value="all">All Status</MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: 'primary.main' }}>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'email'}
                  direction={sortField === 'email' ? sortOrder : 'asc'}
                  onClick={() => handleSort('email')}
                  sx={{ color: 'white', '&:hover': { color: 'white' } }}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: 'white' }}>User ID</TableCell>
              <TableCell sx={{ color: 'white' }}>Device ID</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'status'}
                  direction={sortField === 'status' ? sortOrder : 'asc'}
                  onClick={() => handleSort('status')}
                  sx={{ color: 'white', '&:hover': { color: 'white' } }}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'created_at'}
                  direction={sortField === 'created_at' ? sortOrder : 'asc'}
                  onClick={() => handleSort('created_at')}
                  sx={{ color: 'white', '&:hover': { color: 'white' } }}
                >
                  Created At
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'last_successful_login'}
                  direction={sortField === 'last_successful_login' ? sortOrder : 'asc'}
                  onClick={() => handleSort('last_successful_login')}
                  sx={{ color: 'white', '&:hover': { color: 'white' } }}
                >
                  Last Successful Login
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: 'white' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7} align="center" sx={{ py: 4 }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : filteredUsers.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} align="center" sx={{ py: 4 }}>
                  <Typography variant="subtitle1" color="text.secondary">
                    No users found
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              filteredUsers.map((user) => (
                <TableRow 
                  key={user.email}
                  sx={{ 
                    '&:hover': { 
                      backgroundColor: 'action.hover'
                    }
                  }}
                >
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.userid || 'N/A'}</TableCell>
                  <TableCell>{user.device_id || 'N/A'}</TableCell>
                  <TableCell>
                    <Chip
                      label={user.status || 'inactive'}
                      color={getStatusColor(user.status || 'inactive') as any}
                      size="small"
                      sx={{ minWidth: 80 }}
                    />
                  </TableCell>
                  <TableCell>{formatDate(user.created_at)}</TableCell>
                  <TableCell>{formatDate(user.last_successful_login)}</TableCell>
                  <TableCell>
                    <Tooltip title="View Accounts">
                      <IconButton 
                        onClick={() => handleUserClick(user)}
                        color="primary"
                        size="small"
                      >
                        <LoginIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog 
        open={accountsDialogOpen} 
        onClose={() => setAccountsDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Facebook Accounts
          {selectedUser && (
            <Typography variant="subtitle2" color="text.secondary">
              {selectedUser.email}
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          {accountsLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : accountsError ? (
            <Alert severity="error" sx={{ mt: 2 }}>
              {accountsError}
            </Alert>
          ) : accounts.length === 0 ? (
            <Typography color="text.secondary" sx={{ p: 2 }}>
              No accounts found
            </Typography>
          ) : (
            <List>
              {accounts.map((account) => (
                <ListItem key={account.account_id}>
                  <ListItemText
                    primary={account.name}
                    secondary={`Account ID: ${account.account_id}`}
                  />
                  <ListItemSecondaryAction>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Chip
                        icon={<CampaignIcon />}
                        label={`${account.active_campaigns} campaigns`}
                        size="small"
                        color="primary"
                      />
                    </Stack>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAccountsDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AccountManagement; 