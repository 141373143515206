import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';

const SplashScreen: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // 10 saniye sonra LoginPage'e yönlendir
    const timer = setTimeout(() => {
      navigate('/login');
    }, 2000);

    // Component unmount olduğunda timer'ı temizle
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F0F2F5',
      }}
    >
      {/* Mavi loader */}
      <CircularProgress 
        size={60} 
        thickness={4} 
        sx={{ 
          color: '#1877F2'  // Facebook mavi rengi
        }} 
      />
    </Box>
  );
};

export default SplashScreen; 