import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1877f2',
      dark: '#166fe5',
    },
    secondary: {
      main: '#42b72a',
      dark: '#36a420',
    },
  },
  typography: {
    fontFamily: [
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
}); 