import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Tooltip,
  Chip,
  SvgIcon,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ImageIcon from '@mui/icons-material/Image';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';

// API URL
const API_URL = 'http://localhost:5001';

interface MediaItem {
  id: string;
  hash: string;
  download_url: string;
  uploaded_at: string;
}

interface StatCardProps {
  title: string;
  value: string | number;
  icon: typeof SvgIcon;
  color: string;
}

const StatCard: React.FC<StatCardProps> = ({ title, value, icon: Icon, color }) => (
  <Card sx={{ height: '100%', backgroundColor: 'background.paper' }}>
    <CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Box
          sx={{
            backgroundColor: `${color}15`,
            borderRadius: '50%',
            p: 1,
            mr: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon sx={{ color }} />
        </Box>
        <Typography variant="h6" component="div" sx={{ color: 'text.primary' }}>
          {title}
        </Typography>
      </Box>
      <Typography variant="h4" component="div" sx={{ color }}>
        {value}
      </Typography>
    </CardContent>
  </Card>
);

const MediaManagement: React.FC = () => {
  const [mediaItems, setMediaItems] = useState<MediaItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchMediaItems();
  }, []);

  const fetchMediaItems = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/v16.0/get_uploaded_images`, {
        email: localStorage.getItem('userEmail'),
      });

      if (response.data.success) {
        setMediaItems(response.data.images || []);
      }
    } catch (error) {
      console.error('Error fetching media items:', error);
      setError('Failed to fetch media items');
    } finally {
      setLoading(false);
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setOpenDialog(true);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('email', localStorage.getItem('userEmail') || '');

      const response = await axios.post(`${API_URL}/v16.0/upload_image`, formData, {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1));
          setUploadProgress(progress);
        },
      });

      if (response.data.success) {
        fetchMediaItems();
        setOpenDialog(false);
        setSelectedFile(null);
        setUploadProgress(0);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setError('Failed to upload file');
    }
  };

  const handleCopyHash = async (hash: string) => {
    try {
      await navigator.clipboard.writeText(hash);
    } catch (error) {
      console.error('Error copying hash:', error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await axios.post(`${API_URL}/v16.0/delete_image`, {
        email: localStorage.getItem('userEmail'),
        image_id: id,
      });
      setMediaItems(mediaItems.filter(item => item.id !== id));
    } catch (error) {
      console.error('Error deleting media item:', error);
      setError('Failed to delete media item');
    }
  };

  const stats = {
    totalImages: mediaItems.length,
    recentUploads: mediaItems.filter(item => {
      const uploadDate = new Date(item.uploaded_at);
      const now = new Date();
      const diffDays = Math.floor((now.getTime() - uploadDate.getTime()) / (1000 * 60 * 60 * 24));
      return diffDays < 7;
    }).length,
  };

  if (loading && !mediaItems.length) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h5" sx={{ color: 'text.primary' }}>
          Media Management
        </Typography>
        <Button
          variant="contained"
          component="label"
          startIcon={<CloudUploadIcon />}
          sx={{ 
            backgroundColor: 'primary.main',
            '&:hover': { backgroundColor: 'primary.dark' }
          }}
        >
          Upload Media
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={handleFileSelect}
          />
        </Button>
      </Box>

      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <StatCard
            title="Total Images"
            value={stats.totalImages}
            icon={ImageIcon}
            color="#2196f3"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StatCard
            title="Recent Uploads"
            value={stats.recentUploads}
            icon={CheckCircleIcon}
            color="#66bb6a"
          />
        </Grid>
      </Grid>

      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      <Grid container spacing={3}>
        {mediaItems.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <Card sx={{ 
              height: '100%', 
              backgroundColor: 'background.paper',
              '&:hover': { 
                transform: 'translateY(-4px)',
                transition: 'transform 0.2s ease-in-out',
              }
            }}>
              <CardMedia
                component="img"
                height="200"
                image={item.download_url}
                alt="Media item"
                sx={{ objectFit: 'cover' }}
              />
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Chip
                    label={new Date(item.uploaded_at).toLocaleDateString()}
                    size="small"
                    sx={{ mr: 1 }}
                  />
                </Box>
                <Typography variant="body2" color="text.secondary" noWrap>
                  Hash: {item.hash}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Tooltip title="Copy Hash">
                  <IconButton 
                    onClick={() => handleCopyHash(item.hash)}
                    size="small"
                    sx={{ color: 'primary.main' }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton 
                    onClick={() => handleDelete(item.id)}
                    size="small"
                    sx={{ color: 'error.main' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          sx: { backgroundColor: 'background.paper' }
        }}
      >
        <DialogTitle sx={{ color: 'text.primary' }}>Upload Media</DialogTitle>
        <DialogContent>
          <Box sx={{ width: '100%', mt: 2 }}>
            <Typography variant="body1" gutterBottom sx={{ color: 'text.primary' }}>
              Selected file: {selectedFile?.name}
            </Typography>
            {uploadProgress > 0 && (
              <Box sx={{ width: '100%', mt: 2 }}>
                <LinearProgress variant="determinate" value={uploadProgress} />
                <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
                  {uploadProgress}%
                </Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} sx={{ color: 'text.secondary' }}>
            Cancel
          </Button>
          <Button 
            onClick={handleUpload} 
            variant="contained"
            disabled={!selectedFile || uploadProgress > 0}
            sx={{ 
              backgroundColor: 'primary.main',
              '&:hover': { backgroundColor: 'primary.dark' }
            }}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MediaManagement; 